import { filter, get } from "lodash-es";

export const formatProductPlacement = (placement) => {
  switch (placement) {
    case "shelf":
      return "I hyllan";
    case "behindRegister":
      return "Bakom kassan";
    case "onDemand":
      return "Beställningsvara";
    case "other":
      return "Annat";
    default:
      return placement;
  }
};

export const formatCompetitionChoice = (choice) => {
  switch (choice) {
    case "habit":
      return "Vana";
    case "betterProduct":
      return "Speciell/Bättre egenskap";
    case "betterPrice":
      return "Bättre pris";
    case "notRecommendingCompetitor":
      return "Rekommenderar ej";
    case "other":
      return "Annat";
    default:
      return choice;
  }
};
export const formatShelfAppearance = (appearance) => {
  switch (appearance) {
    case "asExpected":
      return "Som förväntat";
    case "didNotExist":
      return "Fanns ej i hyllan";
    case "notRefilled":
      return "Ej påfyllt";
    case "messy":
      return "Ostädat";
    case "campaignMissing":
      return "Kampanj fattades";
    default:
      return appearance;
  }
};

export const formatPharmacy = (pharmacy) => {
  const nameMap = {
    apoteketHjartat: "Apotek Hjärtat",
    apotekGruppen: "Apoteksgruppen",
    lloyds: "DOZ Apotek",
    dos: "DOZ Apotek",
    kronans: "Kronans Apotek",
    apoteket: "Apoteket AB",
    a_apoteket: "A-Apoteket",
    apotekaren_amba: "Apotekeren Amba",
    // sygehus_apotek: 'Sygehusapotek',
    // apotek_ej_kedja: 'Apotek - ej kedja (DK)',
    pharma_plus: "Pharma+",
    ugle: "Ugle",
    apotek_1: "Apotek1",
    vitus: "Vitus Apotek",
    
  };

  const name = nameMap[pharmacy] || pharmacy;
  if (name.toLowerCase().includes("lloyds")) {
    // onödigt...
    const renamedName = name.replace("Lloyds", "DOZ");
    return renamedName;
  }
  return name;
};

export const getPharmacyValues = (customerName) => {
  const swe = [
    "apoteketHjartat",
    "apotekGruppen",
    "lloyds",
    "kronans",
    "apoteket",
  ];
  const dan = [
    "a_apoteket",
    "apotekaren_amba",
    "pharma_plus",
    "ugle",
    // 'sygehus_apotek',
    // 'apotek_ej_kedja',
  ];
  const norway = ["apotek_1", "vitus"];
  return getCustomerValues(customerName, swe, dan, norway);
};

export const getBrandknowledgeKey = (key, isVet) => {
  if (key === "expedites_regularly" && isVet) {
    return "expedites_regularly_vet";
  } else if (key === "expedites_sometimes" && isVet) {
    return "expedites_sometimes_vet";
  }
  return key;
};

export const getCustomerValues = (customerName, swe, dan, norway = []) => {
  if (customerName.toLowerCase() === "repolar") {
    return [...swe, ...dan, ...norway];
  }
  return swe;
};

export const getBrandKnowledge = (customerName, primary, secondary) => {
  if (["repolar", "peptonicmedical"].includes(customerName.toLowerCase())) {
    return [...primary, ...secondary];
  }
  return primary;
};

export const getCityValues = (customerName) => {
  const swe = ["stockholm", "goteborg", "skane", "uppsala"];
  const dan = [
    "syddanmark",
    "sjaelland",
    "midtjylland",
    "nordjylland",
    "hovestaden",
  ];

  const norway = ["ostlandet", "trondelag", "vestlandet", "sorlandet"];

  return getCustomerValues(customerName, swe, dan, norway);
};
export const formatBrandKnowledge = (knowledge) => {
  const brandKnowledge = {
    primary: "Förstahandsval",
    recommending: "Rek. regelbundet",
    testing: "Testar/Rek. ibland",
    understands: "Förstår",
    knowledge: "Kännedom",
    none: "Ingen kännedom",
    ej_intresserad_nu: "Ej intresserad nu",
    visst_intresse: "Visst intresse",
    hogt_intresse: "Högt intresse",
    ska_bestalla: "Ska beställa",
    lagt_bestallning: "Lagt beställning",
    kan_ej_bestalla: "Kan ej beställa",
  };

  return brandKnowledge[knowledge] || knowledge;
};

export const formatCity = (city) => {
  const cityMap = {
    stockholm: "Stockholm/Svealand",
    goteborg: "Göteborg/Götaland",
    skane: "Skåne/Södra Götaland",
    uppsala: "Norrland",
    syddanmark: "Syddanmark",
    sjaelland: "Sjaelland",
    midtjylland: "Midtjylland",
    nordjylland: "Nordjylland",
    hovestaden: "Hovestaden",
    ostlandet: "Østlandet",
    sorlandet: "Sørlandet",
    trondelag: "Trøndelag",
    vestlandet: "Vestlandet",
  };
  return cityMap[city] || city;
};

export const formatMeetingNr = (meetingNr) => {
  switch (meetingNr) {
    case "1":
      return "Möte 1";
    case "2":
      return "Möte 2";
    case "3":
      return "Möte 3";
    case "4":
      return "Möte 4";
    default:
      return meetingNr;
    // code block
  }
};

export const generateDataPoint = (
  data,
  attribute,
  key,
  formatFunction = null
) => {
  const numberOfPoints = filter(data, (item) => {
    const value = get(item, attribute, "");
    return value ? value.includes(key) : false;
  }).length;
  return {
    value: numberOfPoints,
    name: formatFunction ? formatFunction(key) : key,
  };
};

export const getInitialCenter = (meetingsFilter) => {
  if (meetingsFilter.city.includes("stockholm")) {
    return { lat: 59.3293, lng: 18.0686 };
  }
  if (meetingsFilter.city.includes("uppsala")) {
    //Note: THIS IS key word uppsala but its for Umeå (yes confusing)
    return { lat: 63.824, lng: 20.252 };
  }
  if (meetingsFilter.city.includes("skane")) {
    return { lat: 55.70584, lng: 13.19321 };
  }
  if (meetingsFilter.city.includes("goteborg")) {
    return { lat: 57.70716, lng: 11.96679 };
  }
  if (meetingsFilter.city.includes("syddanmark")) {
    return { lat: 55.276928, lng: 10.613332 };
  }
  if (meetingsFilter.city.includes("sjaelland")) {
    return { lat: 55.463252, lng: 11.721498 };
  }
  if (meetingsFilter.city.includes("midtjylland")) {
    return { lat: 56.302139, lng: 9.302777 };
  }
  if (meetingsFilter.city.includes("nordjylland")) {
    return { lat: 56.830742, lng: 9.493053 };
  }
  if (meetingsFilter.city.includes("hovestaden")) {
    return { lat: 55.675181, lng: 12.549326 };
  }
  if (meetingsFilter.city.includes("ostlandet")) {
    return { lat: 59.911491, lng: 10.757933 };
  }
  if (meetingsFilter.city.includes("sorlandet")) {
    return { lat: 58.48222, lng: 8.7825 };
  }
  if (meetingsFilter.city.includes("trondelag")) {
    return { lat: 63.446827, lng: 10.421906 };
  }
  if (meetingsFilter.city.includes("vestlandet")) {
    return { lat: 60.3880473, lng: 5.3318581 };
  } else {
    return { lat: 59.3293, lng: 18.0686 };
  }
};
