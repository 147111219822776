import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import "chartist/dist/scss/chartist.scss";
import { GoogleApiWrapper } from "google-maps-react";

import { useLazyQuery, useQuery } from "react-apollo";
import { getMeetings, getCustomer } from "../../graphql";
import { get } from "lodash-es";
import {
  getCityValues,
  getPharmacyValues,
  getInitialCenter,
} from "../../utils";
import LoadingPage from "../../components/LoadingPage";
import ListingChart from "../../components/ListingChart";
import CompetitorChart from "../../components/CompetitorChart";
import ShelfAppearanceChart from "../../components/ShelfAppearanceChart";
import ProductKnowledgeChart from "../../components/ProductKnowledgeChart";
import CompetitorReasonChart from "../../components/CompetitorReasonChart";

import { useTranslation } from "react-i18next";
import { Filters } from "./components/Filters";
import { ProductSideBar } from "./components/ProductSideBar";
import { MeetingsMapColumn } from "./components/MeetingsMapColumn";
import { getBrandKnowledge } from "../../utils";
// border: solid green;

const filterData = (item, meetingsFilter) => {
  const includesOrEmptyChoice = (values, itemValue) => {
    if (!itemValue) {
      return false;
    }
    return values.length === 0 || values.includes(itemValue.toString());
  };
  return (
    includesOrEmptyChoice(meetingsFilter.meetingNr, item.meetingNumber) &&
    includesOrEmptyChoice(meetingsFilter.product, item.productId) &&
    includesOrEmptyChoice(meetingsFilter.pharmacy, item.pharmacy) &&
    includesOrEmptyChoice(meetingsFilter.city, item.region)
  );
};

const Dashboard = (props) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  // const handleChangeLanguage = () => {
  //   const newLanguage = currentLanguage === "en" ? "pt" : "en";
  //   setCurrentLanguage(newLanguage);
  //   changeLanguage(newLanguage);
  // };

  const [startTime, setStartTime] = useState(null);
  // const [symbolStartTime, setSymbolStartTime] = useState(null)
  const [endTime, setEndTime] = useState(new Date());
  const [data, setData] = useState([]);
  const [bufferData, setBufferData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [products, setProducts] = useState([]);
  const [rxProducts, setRx] = useState([]);
  const [customerLogo, setCustomerLogo] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [currentToken, setNextToken] = useState(null);
  const {
    data: customer,
    loading,
    error,
  } = useQuery(getCustomer, {
    onCompleted: (data) => {
      const customerProducts = get(
        data,
        ["getCustomer", "items", 0, "productNames"],
        []
      );
      const costomerProductsRX =
        get(data, ["getCustomer", "items", 0, "productNamesRX"], []) || [];
      setRx(costomerProductsRX);
      setProducts([...customerProducts, ...costomerProductsRX]);
      const name = get(
        data,
        ["getCustomer", "items", 0, "customerName"],
        "No customer"
      );
      const language = get(data, ["getCustomer", "items", 0, "language"], "sv");
      changeLanguage(language);
      setCustomerName(name);
      const logo = get(data, ["getCustomer", "items", 0, "customerLogo"], null);
      setCustomerLogo(logo);
      const newState = { ...meetingsFilter };
      newState.product = customerProducts.length > 0 ? customerProducts[0] : "";
      if (costomerProductsRX.includes(newState.product)) {
        newState.isRxProduct = true;
      }
      setFilter(newState);
      // setStartTime()
    },
    onError: (e) => {
      //TODO REMOVE
      console.log(e);
    },
  });
  const [stillFetching, setStillFetching] = useState(true);
  const [loadingVisitor, setLoadingVisitor] = useState(true);

  const [meetingsFilter, setFilter] = useState({
    product: "",
    isRxProduct: false,
    city: getCityValues(customerName),
    pharmacy: getPharmacyValues(customerName),
    meetingNr: ["1", "2", "3", "4"],
  });

  useEffect(() => {
    setFilter((filter) => ({
      ...filter,
      city: getCityValues(customerName),
      pharmacy: getPharmacyValues(customerName),
    }));
  }, [customerName]);
  const [fetchMeetings, { fetchMore }] = useLazyQuery(getMeetings, {
    onCompleted: (result) => {
      const items = get(result, ["getMeetings", "items"], []);
      const nextToken = get(result, ["getMeetings", "nextToken"], null);
      console.log("items", items[0]);
      const dos = items.filter((item) => item.pharmacy === "doz");
      console.log("dos", dos);

      const remappedItems = items.map((item) => ({
        ...item,
        pharmacy: item.pharmacy === "doz" ? "lloyds" : item.pharmacy,
      }));

      setBufferData((prev) => {
        const newBuffer = [...prev, ...remappedItems];
        if (nextToken) {
          setNextToken(nextToken);
        } else {
          setStillFetching(false);
          setLoadingVisitor(false);
          setData([...prev, ...remappedItems]);
          const filteredItems = newBuffer.filter((item) =>
            filterData(item, meetingsFilter)
          );
          setFilteredData(filteredItems);
        }
        return newBuffer;
      });

      // setSymbolStartTime(data[data.length - 1].meetingDate)
    },
    onError: (error) => {
      setLoadingVisitor(false);
    },
  });

  useEffect(() => {
    fetchMeetings({
      variables: {
        // pharmacy: 'apoteketHjartat',
        startTime:
          startTime && startTime.toISOString().slice(0, 11) + "00:00:00.002Z",
        endTime:
          endTime && endTime.toISOString().slice(0, 11) + "23:59:00.002Z", //.slice(0, 11) + '23:59:00.002Z',
        nextToken: currentToken,
      },
    });
  }, [fetchMeetings, startTime, endTime, currentToken]);

  const [initialCenter, setInitialCenter] = useState({
    lat: 59.3293,
    lng: 18.0686,
  });
  useEffect(() => {
    const newState = data.filter((item) => filterData(item, meetingsFilter));
    setFilteredData(newState);

    setInitialCenter(getInitialCenter(meetingsFilter));
  }, [meetingsFilter, data]);

  if (loading || stillFetching) {
    return <LoadingPage />;
  }

  const handelSetStartTime = (value) => {
    setNextToken(null);
    setData([]);
    setBufferData([]);
    setFilteredData([]);
    setStartTime(value);
    setLoadingVisitor(true);

    // setStillFetching(true)
  };
  const handelSetEndTime = (value) => {
    setNextToken(null);
    setData([]);
    setBufferData([]);
    setFilteredData([]);
    setEndTime(value);
    setLoadingVisitor(true);
    // setStillFetching(true)
  };
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col md={12} xs={12}>
            <Filters
              isVetCustomer={customerName === "Vetoquinol"} //Change this to customer attribute instead
              setFilter={setFilter}
              meetingsFilter={meetingsFilter}
              customerName={customerName}
              products={products}
              rxProducts={rxProducts}
              startTime={startTime}
              endTime={endTime}
              handelSetStartTime={handelSetStartTime}
              handelSetEndTime={handelSetEndTime}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={2} md={2}>
            <ProductSideBar
              meetingsFilter={meetingsFilter}
              customerLogo={customerLogo}
            />
          </Col>
          <Col xl={7} md={7}>
            <Row>
              <Col>
                <Card style={{ height: "450px" }}>
                  <CardBody>
                    <h4 className="mt-0 header-title mb-4">
                      {t("dashboard.brand_knowledge")}
                    </h4>
                    <div id="scatter-chart" className="e-chart">
                      <ProductKnowledgeChart
                        data={filteredData}
                        isVet={meetingsFilter?.vetView}
                        isRx={meetingsFilter?.isRxProduct}
                        brandKnowledgeKeys={
                          meetingsFilter?.isRxProduct
                            ? [
                                "primary",
                                "expedites_regularly",
                                "expedites_sometimes",
                                "understands",
                                "knowledge",
                                "no_knowledge",
                              ]
                            : getBrandKnowledge(
                                customerName,
                                [
                                  "primary",
                                  "recommending",
                                  "testing",
                                  "understands",
                                  "knowledge",
                                  "none",
                                ],
                                [
                                  "ej_intresserad_nu",
                                  "visst_intresse",
                                  "hogt_intresse",
                                  "ska_bestalla",
                                  "lagt_bestallning",
                                  "kan_ej_bestalla",
                                ]
                              )
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row style={{ marginBottom: "2rem" }}>
                      <Col md={6}>
                        <div className="text-center">
                          <h4 className="mt-0 header-title mb-4">
                            {!meetingsFilter?.isRxProduct
                              ? t("dashboard.recommended_at_pharmacy")
                              : meetingsFilter?.vetView
                              ? t("dashboard.recommended_at_rx_vet")
                              : t("dashboard.recommended_at_rx_pharmacy")}
                          </h4>
                          <CompetitorChart data={filteredData} />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-center">
                          <h4 className="mt-0 header-title mb-4">
                            {!meetingsFilter?.isRxProduct
                              ? t("dashboard.why_competitors_recommended")
                              : meetingsFilter?.vetView
                              ? t(
                                  "dashboard.why_competitors_recommended_rx_vet"
                                )
                              : t(
                                  "dashboard.why_competitors_recommended_rx_pharmacy"
                                )}
                          </h4>
                          <CompetitorReasonChart
                            data={filteredData}
                            isRx={meetingsFilter?.isRxProduct}
                            reasonKeys={
                              meetingsFilter?.isRxProduct
                                ? [
                                    "special_character",
                                    "dosage",
                                    "formula",
                                    "active_ingredient",
                                    "habit",
                                    "price",
                                    "other",
                                  ]
                                : [
                                    "betterProduct",
                                    "habit",
                                    "betterPrice",
                                    "notRecommendingCompetitor",
                                    "other",
                                    "lacking_knowledge",
                                  ]
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    {!(
                      meetingsFilter?.isRxProduct && meetingsFilter.vetView
                    ) && (
                      <Row>
                        <Col md={6}>
                          <div className="text-center">
                            <ListingChart
                              vetView={meetingsFilter?.vetView}
                              data={filteredData}
                              isRx={meetingsFilter?.isRxProduct}
                              customerName={customerName}
                            />
                            <h4 className="mt-0 header-title mb-4">
                              {meetingsFilter?.isRxProduct
                                ? t("dashboard.stock_status")
                                : t("dashboard.product_placement")}
                            </h4>
                          </div>
                        </Col>
                        {!meetingsFilter?.isRxProduct && (
                          <Col md={6}>
                            <div className="text-center">
                              <ShelfAppearanceChart data={filteredData} />
                              <h4 className="mt-0 header-title mb-4">
                                {t("dashboard.shelf_appearance")}
                              </h4>
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <MeetingsMapColumn
            google={props.google}
            meetingsFilter={meetingsFilter}
            loadingVisitor={loadingVisitor}
            filteredData={filteredData}
            initialCenter={initialCenter}
            isVet={meetingsFilter?.vetView}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};

// export default Dashboard
const LoadingContainer = (props) => <div>Loading...</div>;

export default GoogleApiWrapper({
  apiKey: "AIzaSyBKeJ1dOGpPZgpdMUA2mTv4WmgZAxWQKLQ",
  LoadingContainer: LoadingContainer,
})(Dashboard);
